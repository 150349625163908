import React from "react";

import styled, { CSSObject, keyframes } from "styled-components";

import { header } from "../../globals";
import { formatDifference } from "../../lib/utils";
import { Meeting } from "../../domain/meeting";
import { useAppState } from "../../state";
import useVideoContext from "../../hooks/useVideoContext";
import useParticipantNetworkQualityLevel from "../../hooks/useParticipantNetworkQualityLevel";
import { NetworkQualityIndicator } from "../NetworkQualityIndicator";
import { ParticipantCount } from "./ParticipantCount";
import { Message } from "react-message-context";

const shared: CSSObject = {
    padding: header.padding,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100vw",
    zIndex: 1,
};

const FooterContainer = styled("footer")({
    ...shared,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))",
});

const blinking = keyframes`
    0% {
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.0;
    }
`;

const Rec = styled.div`
    width: 12px;
    height: 12px;
    background: red;
    opacity: 0;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    animation-name: ${blinking};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`;

const TextContainer = styled("div")({
    textTransform: "uppercase",
});

const FooterItemSpacer = styled.div`
    display: inline-block;
    width: 0.8rem;
`;

interface Props {
    meeting: Meeting;
}

export const Footer: React.FC<Props> = ({ meeting }) => {
    const lastUpdate = new Date(meeting.updatedAt).getTime();
    const [diff, setDiff] = React.useState(Date.now() - lastUpdate);
    const {
        session: { role },
    } = useAppState();
    const { room } = useVideoContext();
    const qualityLevel = useParticipantNetworkQualityLevel(room.localParticipant);

    React.useEffect(() => {
        const interval = window.setInterval(() => {
            setDiff(Date.now() - lastUpdate);
        }, 1000);
        return () => window.clearInterval(interval);
    }, [lastUpdate]);

    return (
        <FooterContainer>
            <TextContainer className="text-white">
                <Rec />
                <Message id={`meeting.states.${meeting.state}`} /> {formatDifference(diff)}
            </TextContainer>
            <FooterItemSpacer />
            <NetworkQualityIndicator qualityLevel={qualityLevel} />
            {role === "host" && (
                <>
                    <FooterItemSpacer />
                    <ParticipantCount />
                </>
            )}
        </FooterContainer>
    );
};
