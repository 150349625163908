import React, { useState } from "react";

import { Button } from "@op/opux";
import { IconAlert, IconInfo } from "@op/opux-icons/indicator";
import { IconChat } from "@op/opux-icons/action";
import { useMessageGetter } from "react-message-context";
import styled from "styled-components";

import { ReactComponent as EndedIllustration } from "../../../../assets/img/Ended_user_illustration.svg";
import { Session } from "../../../../domain";
import { RealtorModal } from "../../../RealtorModal";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    text-align: center;
`;

const ImgContainer = styled.div`
    padding: 2.4rem 1.8rem;
    flex: 1;
    width: 70%;
`;

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 1.8rem;
`;

const BtnLabel = styled.span`
    margin-top: 1.2rem;
`;

interface ViewerLobbyProps {
    session: Session;
}

export const EndedLobby: React.FC<ViewerLobbyProps> = ({ session }) => {
    const messages = useMessageGetter("lobby.ended");
    const misc = useMessageGetter("misc");
    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    return (
        <Container>
            <ImgContainer>
                <EndedIllustration />
            </ImgContainer>
            <h1>{messages("title")}</h1>
            <p>{messages("message")}</p>

            <div style={{ height: "1.8rem" }} />
            <BtnContainer>
                <BtnWrapper>
                    <Button
                        icon={IconInfo}
                        size="large"
                        minified
                        iconLabel={misc("info")}
                        href={session.info1 || undefined}
                        target="_blank"
                        disabled={!session.info1}
                    />
                    <BtnLabel>{misc("info")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    {/* TODO: Replace with proper icon */}
                    <Button
                        icon={IconAlert}
                        size="large"
                        minified
                        iconLabel={misc("floorPlan")}
                        href={session.info2 || undefined}
                        target="_blank"
                        disabled={!session.info2}
                    />
                    <BtnLabel>{misc("floorPlan")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    <Button icon={IconChat} size="large" minified iconLabel={misc("realtor")} onClick={onOpen} />
                    <BtnLabel>{misc("realtor")}</BtnLabel>
                </BtnWrapper>
            </BtnContainer>
            <RealtorModal session={session} open={open} onClose={onClose} />
        </Container>
    );
};
