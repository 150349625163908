import { useState, useEffect, useCallback } from "react";
import { SQSTopic } from "../../../domain";

interface SubscriptionResponse {
    status: "OK" | "ERROR";
    message?: string;
    requestId?: string;
    subscriptions?: SQSTopic[];
}

const hex = "0123456789abcdef";

const genId = (length = 12) => {
    let id = "";
    for (let i = 0; i < length; i++) {
        id += hex.charAt(Math.floor(hex.length * Math.random()));
    }
    return id;
};

const defaultSubscriptions: SQSTopic[] = ["meeting"];

export function useSubscriptions(ws: WebSocket | null) {
    // By default we are subscribed to "meeting"
    const [subscriptions, setSubscriptions] = useState<SQSTopic[]>(defaultSubscriptions);

    useEffect(() => {
        if (ws) {
            const onMessage = (event: MessageEvent) => {
                let data: SubscriptionResponse;
                try {
                    data = JSON.parse(event.data as string);
                } catch (e) {
                    console.warn(e);
                    return;
                }
                if (data && data.status) {
                    if (data.status === "OK" && data.subscriptions) {
                        console.debug(data);
                        setSubscriptions(data.subscriptions);
                    } else if (data.message !== "pong") {
                        console.warn("Subscription failed", data);
                    }
                }
            };

            ws.addEventListener("message", onMessage);
            // TODO: Set default subscriptions on cleanup?
            return () => ws.removeEventListener("message", onMessage);
        }
    }, [ws]);

    /**
     * Subscribe to given topics.
     * TODO: Do we want to use request-response?
     */
    const subscribe = useCallback(
        (...topics: SQSTopic[]) => {
            const request = {
                requestId: genId(),
                action: "subscribe",
                topics,
            };
            ws?.send(JSON.stringify(request));
        },
        [ws],
    );

    return { subscriptions, subscribe };
}
