import React from "react";

import styled from "styled-components";

import logo from "../../../assets/img/logo_large.png";

const Img = styled.img``;

interface Props {
    height?: number;
    label?: string;
    onDarkBg?: boolean;
}

export const LogoSato: React.FC<Props> = ({ height, label }) => (
    <Img alt={label || "Logo"} src={logo} height={height} />
);
