import React, { useCallback, useEffect, useRef } from "react";

import { Button } from "@op/opux";
import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";

import useTermsOfServiceContext from "../../../../hooks/useTermsOfServiceContext";

export const TermsOfService: React.FC<{ role: "host" | "viewer" }> = ({ role }) => {
    const { open, setOpen, onCloseHandler } = useTermsOfServiceContext();
    const ref = useRef<any>();
    const messages = useMessageGetter("termsOfService");

    useEffect(() => {
        if (ref.current) {
            if (open && role === "viewer") {
                ref.current.open();
            } else {
                ref.current.close();
            }
        }
    }, [open, ref, role]);

    const close = useCallback(() => {
        setOpen(false);
        if (onCloseHandler) {
            onCloseHandler();
        }
    }, [setOpen, onCloseHandler]);

    return (
        <Modal
            appElement={document.getElementById("root") || undefined}
            title={messages("title")}
            ref={ref}
            footer={
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                    <Button onClick={close} compact>
                        <Message id="actions.close" />
                    </Button>
                </div>
            }
        >
            <p>{messages("disclaimer1")}</p>
            <br />
            <p>{messages("disclaimer2")}</p>
        </Modal>
    );
};
