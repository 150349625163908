import React, { useRef, useEffect } from "react";

import { Track, LocalVideoTrack, RemoteVideoTrack } from "twilio-video";
import styled from "styled-components";

const Video = styled("video")({
    width: "100%",
    maxHeight: "100vh",
    objectFit: "contain",
});

interface VideoTrackProps {
    track: LocalVideoTrack | RemoteVideoTrack;
    isLocal?: boolean;
    priority?: Track.Priority | null;
}

export default function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ref = useRef<HTMLVideoElement>(null!);

    useEffect(() => {
        const el = ref.current;
        el.muted = true;
        if (track.setPriority && priority) {
            track.setPriority(priority);
        }
        track.attach(el);
        return () => {
            track.detach(el);
            if (track.setPriority && priority) {
                // Passing `null` to setPriority will set the track's priority to that which it was published with.
                track.setPriority(null);
            }
        };
    }, [track, priority]);

    // The local video track is mirrored.
    const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== "environment";
    const style = isLocal && isFrontFacing ? { transform: "rotateY(180deg)" } : {};

    return <Video ref={ref} style={style} />;
}
