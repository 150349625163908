import { useState, useEffect } from "react";
import { apiConfig } from "../../../config";

interface WebSocketProps {
    onError?: (error: Error) => void;
    onClose?: () => void;
    meetingId: string;
}

/**
 * How many times we will try connecting the websocket?
 */
const MAX_RECONNECTION_COUNT = 5;
/**
 * How long we will wait until reconnecting?
 */
const RECONNECTION_DELAY_MS = 2000;

export function useWebSocket({ meetingId, onClose, onError }: WebSocketProps) {
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [state, setState] = useState<"disconnected" | "connected" | "reconnecting">("disconnected");
    const [reconnectionCount, setReconnectionCount] = useState(0);
    const [pageRefreshNeeded, setPageRefreshNeeded] = useState(false);

    useEffect(() => {
        const ws = new WebSocket(`${apiConfig.socketUrl}?meetingId=${meetingId}`, ["home-alone"]);
        let timeoutId: number | null = null;

        function reconnect() {
            if (reconnectionCount >= MAX_RECONNECTION_COUNT) {
                // Giving up, let's just ask the user to refresh the page
                setPageRefreshNeeded(true);
                return;
            }
            setState("reconnecting");
            // Reconnect - setting new `lastDisconnect` should trigger new WebSocket
            timeoutId = setTimeout(() => {
                setReconnectionCount(reconnectionCount + 1);
            }, RECONNECTION_DELAY_MS);
        }

        ws.onopen = () => {
            setWs(ws);
            setState("connected");
        };

        ws.onclose = (e) => {
            console.debug("Connection closed, code " + e.code);
            if (e.code !== 1001 && e.code !== 1000) {
                reconnect();
            } else {
                // Code is 1000 if API Gateway closes the connection (e.g. after removing participant)
                setState("disconnected");
                setWs(null);
                if (onClose) {
                    onClose();
                }
            }
        };

        ws.onerror = (event) => {
            console.error("WebSocket error", event);
            const error = new Error("WebSocket error");
            if (onError) {
                onError(error);
            }
            // Reconnect - setting new `lastDisconnect` should trigger new WebSocket
            reconnect();
        };
        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
            if (ws && ws.readyState <= WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [meetingId, onClose, onError, reconnectionCount, setReconnectionCount, setPageRefreshNeeded]);

    return { ws, state, pageRefreshNeeded };
}
