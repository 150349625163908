import React from "react";

import { Button } from "@op/opux";
import { IconVideocamOff, IconVideocamOn } from "@op/opux-icons/control";
import { useMessageGetter } from "react-message-context";

import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle";

export const ToggleVideoButton: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const labels = useMessageGetter("actions");
    return (
        <Button
            size={isMobile ? "default" : "large"}
            minified
            icon={isVideoEnabled ? IconVideocamOn : IconVideocamOff}
            onClick={toggleVideoEnabled}
            iconLabel={labels("toggleVideo")}
            iconProps={{ type: "default" }}
            className="control"
        />
    );
};
