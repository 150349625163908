import React, { useRef, useEffect } from "react";

import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";
import { Button } from "@op/opux";
import { useAppState } from "../../../state";

// Codes that we want to explicitly translate for the user
const KNOWN_CODES = ["0", "53118", "53106", "53105", "53205"];
const KNOWN_CODE_CATEGORIES = ["531", "201"];

/**
 * Convert a twilio error code into a localization key. This has to be
 * manually matched with the translations.
 *
 * @param code Twilio error code
 */
const codeToTranslation = (code: number): string => {
    const stringified = code + "";
    if (KNOWN_CODES.includes(stringified)) {
        return stringified;
    }
    const match = KNOWN_CODE_CATEGORIES.find((c) => stringified.startsWith(c));
    if (match) {
        return `${match}xx`;
    }
    return "other";
};

/**
 * Component for displaying a modal for user when a Twilio error occurs.
 */
export const TwilioErrorAlert: React.FC = () => {
    const ref = useRef<any>();
    const messages = useMessageGetter("twilioError");
    const { twilioError: error, setTwilioError } = useAppState();

    const onRefresh = () => {
        window.location.reload();
    };

    const onClose = () => {
        setTwilioError(null);
    };

    useEffect(() => {
        if (ref.current) {
            if (error) {
                ref.current.open();
            } else {
                ref.current.close();
            }
        }
    }, [error, ref]);

    return (
        <Modal
            appElement={document.getElementById("root") || undefined}
            title={messages("title")}
            footer={
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Button compact onClick={onClose}>
                        <Message id="actions.close" />
                    </Button>
                    <Button compact onClick={onRefresh}>
                        <Message id="actions.reload" />
                    </Button>
                </div>
            }
            ref={ref}
        >
            {error && (
                <div>
                    <p>{messages(`codes.${codeToTranslation(error?.code)}`)}</p>
                    <br />
                    <small>
                        <strong>{messages("errorCode", { code: error.code })}</strong>
                    </small>
                </div>
            )}
        </Modal>
    );
};
