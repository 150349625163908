import React, { useEffect, useRef, useState } from "react";

import { Button } from "@op/opux";
import { IconCallHangup } from "@op/opux-icons/action";
import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";

import useVideoContext from "../../../hooks/useVideoContext";

export const LeaveButton: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const { room } = useVideoContext();
    const labels = useMessageGetter("actions");
    const [modalOpen, setModalOpen] = useState(false);
    const ref = useRef<any>();
    const messages = useMessageGetter("player.leaveDialog");

    const onLeave = () => room.disconnect();

    useEffect(() => {
        if (ref.current) {
            if (modalOpen) {
                ref.current.open();
            } else {
                ref.current.close();
            }
        }
    }, [modalOpen, ref]);
    return (
        <>
            <Button
                size={isMobile ? "default" : "large"}
                minified
                icon={IconCallHangup}
                iconLabel={labels("leave")}
                onClick={() => setModalOpen(true)}
                iconProps={{ type: "default" }}
                className="control"
            />
            <Modal
                ref={ref}
                appElement={document.getElementById("root") || undefined}
                title={messages("title")}
                footer={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button compact onClick={() => setModalOpen(false)}>
                            <Message id="actions.cancel" />
                        </Button>
                        <Button compact onClick={onLeave}>
                            <Message id="actions.leave" />
                        </Button>
                    </div>
                }
            >
                <p>{messages("message")}</p>
            </Modal>
        </>
    );
};
