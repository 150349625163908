import React, { ReactNode, createContext, useCallback } from "react";

import {
    Room,
    LocalAudioTrack,
    LocalVideoTrack,
    TwilioError,
    CreateLocalTrackOptions,
    ConnectOptions,
} from "twilio-video";

import { AppUserRole } from "../../domain";
import useLocalTracks from "./useLocalTracks";
import useRoom from "./useRoom";
import { ErrorCallback } from "../../types";
import useHandleOnDisconnect from "./useHandleOnDisconnect";
import AttachVisibilityHandler from "./AttachVisibilityHandler";

export interface VideoState {
    room: Room;
    localTracks: (LocalAudioTrack | LocalVideoTrack)[];
    isConnecting: boolean;
    connect: (token: string, role: AppUserRole) => Promise<void>;
    onError: ErrorCallback;
    getLocalVideoTrack: (options?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
    getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const VideoContext = createContext<VideoState>(null!);

interface VideoProviderProps {
    options?: ConnectOptions;
    onError?: (error: TwilioError) => void;
    onDisconnect?: (reason?: "host-ended") => void;
    children: ReactNode;
}

export function VideoProvider({
    options,
    children,
    onError = () => {
        /* NOOP */
    },
    onDisconnect = () => {
        /* NOOP */
    },
}: VideoProviderProps) {
    const onErrorCallback = useCallback(
        (error: TwilioError) => {
            console.error("TWILIO ERROR", error);
            onError(error);
        },
        [onError],
    );

    const { localTracks, getLocalAudioTrack, getLocalVideoTrack } = useLocalTracks();
    const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);

    useHandleOnDisconnect(room, onDisconnect);
    return (
        <VideoContext.Provider
            value={{
                localTracks,
                getLocalAudioTrack,
                getLocalVideoTrack,
                room,
                isConnecting,
                connect,
                onError: onErrorCallback,
            }}
        >
            {children}
            {/* 
                The AttachVisibilityHandler component is using the useLocalVideoToggle hook
                which must be used within the VideoContext Provider.
            */}
            <AttachVisibilityHandler />
        </VideoContext.Provider>
    );
}
