import Axios, { AxiosRequestConfig } from "axios";

import { Meeting, MeetingState } from "../domain/meeting";
import { apiConfig } from "../config";
import { querystring } from "../lib/utils";
import { ChatMessageBase } from "../domain/chat-message";
import { Session } from "../domain";

interface MeetingResponse {
    meeting: Meeting;
}

export interface GetMessagesQuery {
    /**
     * Before date-time
     */
    before: string;
    /**
     * Limit results
     */
    limit: number;
    /**
     * Pagination key
     */
    key: string;
}

export interface GetMessagesResponse {
    messages: ChatMessageBase[];
    nextKey: string | null;
}

export class APIClient {
    private axios = Axios.create({
        baseURL: apiConfig.baseUrl,
        timeout: 5000, // TBD
    });

    /**
     * Get meeting.
     *
     * @param meetingId Meeting Kipinä ID
     * @param options Request options
     */
    async getMeeting(meetingId: string, options?: AxiosRequestConfig): Promise<Meeting> {
        const { data } = await this.axios.get<MeetingResponse>(`/meetings/${encodeURIComponent(meetingId)}`, {
            withCredentials: true,
            ...options,
        });
        return data.meeting;
    }

    /**
     * Get meeting token.
     *
     * @param meetingId Meeting Kipinä ID
     * @param options Request options
     */
    async getMeetingToken(meetingId: string, options?: AxiosRequestConfig): Promise<string> {
        const { data } = await this.axios.get<{ token: string }>(`/meetings/${encodeURIComponent(meetingId)}/token`, {
            withCredentials: true,
            ...options,
        });
        return data.token;
    }

    /**
     * Update meeting state, i.e. start demo or live meeting.
     *
     * @param meetingId Meeting Kipinä ID
     * @param state Next state, either `demo` or `live`
     * @param query Query parameters
     * @param options Request options
     */
    async updateMeetingState(meetingId: string, state: MeetingState, options?: AxiosRequestConfig): Promise<Meeting> {
        return (
            await this.axios.put<MeetingResponse>(
                `/meetings/${encodeURIComponent(meetingId)}/state`,
                {
                    state,
                },
                { withCredentials: true, ...options },
            )
        ).data.meeting;
    }

    /**
     * Send a message to the chat.
     *
     * @param content Message content
     * @param meetingId Meeting ID
     * @param query Query params
     * @param options Optional options
     */
    async sendMessage(content: string, meetingId: string, options?: AxiosRequestConfig): Promise<void> {
        await this.axios.post<void>(
            `/meetings/${encodeURIComponent(meetingId)}/messages`,
            { content },
            { withCredentials: true, ...options },
        );
    }

    /**
     * Get meeting messages.
     *
     * @param meetingId The meeting ID
     * @param query Query
     * @param options Options
     */
    async getMessages(
        meetingId: string,
        query?: Partial<GetMessagesQuery>,
        options?: AxiosRequestConfig,
    ): Promise<GetMessagesResponse> {
        const qs = query ? querystring(query) : "";
        const { data } = await this.axios.get<GetMessagesResponse>(
            `/meetings/${encodeURIComponent(meetingId)}/messages?${qs}`,
            { withCredentials: true, ...options },
        );
        return data;
    }

    /**
     * Get current authenticated user.
     *
     * @param meetingId The meeting ID
     * @param options Options
     */
    async getSession(meetingId: string, options?: AxiosRequestConfig): Promise<Session> {
        const data = (await this.axios.get("/auth/sessions/" + meetingId, { withCredentials: true, ...options })).data;
        return data;
    }

    /**
     * Remove a participant from the meeeting.
     *
     * @param meetingId The meeting ID
     * @param identity Identity of the participant
     * @param options Options
     */
    async removeParticipant(meetingId: string, identity: string, options?: AxiosRequestConfig): Promise<void> {
        await this.axios.delete(`/meetings/${meetingId}/participants/${identity}`, {
            withCredentials: true,
            ...options,
        });
    }
}
