import React from "react";

import styled, { CSSObject } from "styled-components";
import { format } from "date-fns";
import { CircledIconPerson } from "@op/opux-icons/circled-service";

import opLogo from "../../../../assets/img/favicon.png";
import { Button } from "@op/opux";
import { IconPerson } from "@op/opux-icons/service";

interface MessageProps {
    isLocal?: boolean;
    content: string | React.ReactNode;
    isOP?: boolean;
    author?: string;
    timestamp?: string;
    onRemoveAuthor?: () => void;
}

const MessageRow = styled.div`
    margin: 0.8rem 0;
    display: flex;
    flex-direction: row;
`;

const BUBBLE_BORDER_RADIUS = "1.4rem";

const bubbleShared: CSSObject = {
    padding: "1.4rem",
    borderBottomLeftRadius: BUBBLE_BORDER_RADIUS,
    borderBottomRightRadius: BUBBLE_BORDER_RADIUS,
    borderTopRightRadius: BUBBLE_BORDER_RADIUS,
    borderTopLeftRadius: BUBBLE_BORDER_RADIUS,
    maxWidth: "100%",
    overflowWrap: "break-word",
};

const SelfBubble = styled("div")({
    ...bubbleShared,
    backgroundColor: "#dcdcdc",
    borderBottomRightRadius: undefined,
});

const Bubble = styled("div")({
    ...bubbleShared,
    borderTopLeftRadius: undefined,
    border: "solid #dcdcdc 1px",
    minHeight: "auto",
});

const Avatar = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingRight: "0.8rem",
});

const Logo = styled.div`
    background-image: url(${opLogo});
    background-size: contain;
    background-repeat: no-repeat;
`;

const Author = styled.p`
    font-size: 1.3rem;
    font-weight: 500;
`;

export const MessageBubble: React.FC<MessageProps> = ({
    isLocal,
    content,
    isOP,
    author,
    timestamp,
    onRemoveAuthor,
}) => (
    <MessageRow style={{ justifyContent: isLocal ? "flex-end" : "flex-start", maxWidth: "100%" }}>
        <Avatar>
            {!isLocal ? (
                isOP ? (
                    <Logo className="ds-circled-icon ds-circled-icon--small" />
                ) : onRemoveAuthor ? (
                    <Button size="hero" icon={IconPerson} iconLabel="Toggle menu" minified onClick={onRemoveAuthor} />
                ) : (
                    <CircledIconPerson size="small" />
                )
            ) : (
                <div className="ds-circled-icon ds-circled-icon--small" />
            )}
        </Avatar>

        <div style={{ maxWidth: "100%" }}>
            {isLocal ? (
                <SelfBubble>{content}</SelfBubble>
            ) : (
                <Bubble>
                    {author && (
                        <Author>
                            {author}
                            {timestamp ? ` ${format(new Date(timestamp), "kk:mm")}` : ""}
                        </Author>
                    )}
                    {content}
                </Bubble>
            )}
            <div style={{ flexGrow: 1 }} />
        </div>
    </MessageRow>
);
