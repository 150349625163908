
module.exports = {
  service: {
    name: function(d) { return "SATO Etäesittely"; }
  },
  misc: {
    realtor: function(d) { return "Välittäjä"; },
    info: function(d) { return "Tiedot"; },
    floorPlan: function(d) { return "Pohjakuva"; }
  },
  actions: {
    join: function(d) { return "Liity etäesittelyyn"; },
    demo: function(d) { return "Aloita testiesittely"; },
    live: function(d) { return "Aloita etäesittely"; },
    send: function(d) { return "Lähetä"; },
    close: function(d) { return "Sulje"; },
    cancel: function(d) { return "Peruuta"; },
    removeParticipant: function(d) { return "Poista vierailija"; },
    complete: function(d) { return "Sulje etäesittely"; },
    leave: function(d) { return "Poistu"; },
    toggleAudio: function(d) { return "Sulje tai salli ääni"; },
    toggleVideo: function(d) { return "Sulje tai salli video"; },
    reload: function(d) { return "Lataa sivu uudelleen"; }
  },
  meeting: {
    states: {
      demo: function(d) { return "Testiesittely"; },
      live: function(d) { return "Etäesittely"; }
    }
  },
  errors: {
    pageNotFound: function(d) { return "Valitettavasti tätä sivua ei löydy"; },
    kickedOut: {
      title: function(d) { return "Sinut on poistettu etäesittelystä"; },
      legal: function(d) { return "Esittelyn sujuvuuden takaamiseksi sinut on poistettu etäesittelystä. Mikäli haluat lisätietoja kohteesta tai varata ajan yksityisesittelyyn, voit ottaa yhteyttä kohteen välittäjään."; }
    }
  },
  lobby: {
    ended: {
      title: function(d) { return "Etäesittely on päättynyt"; },
      message: function(d) { return "Kohteen välittäjä on pättänyt etäesittelyn. Voit tutustua alla olevien linkkien kautta kohteen tietoihin ja halutessasi voit varata yksityisesittelyn ottamalla yhteyttä kohteen välittäjään."; }
    },
    viewer: {
      title: function(d) { return "Tervetuloa etäesittelyyn!"; },
      message1: function(d) { return "Voit liittyä etäesittelyyn hetken kuluttua, kun välittäjämme aloittaa esittelyn."; },
      message2: function(d) { return "Odottaessasi voit tutustua kohteen tietoihin ja välittäjän yhteystietoihin."; },
      startsAt: function(d) { return "Esittely alkaa"; }
    },
    realtor: {
      title: function(d) { return "Valmiina etäesittelyyn!"; },
      message1: function(d) { return "Voit aluksi kokeilla laitteiden ja verkkoyhteyden toimivuutta painamalla 'Aloita testiesittely' -painiketta."; },
      message2: function(d) { return "Kun olet valmis aloittamaan varsinaisen esittelyn, paina 'Aloita etäesittely' -painiketta."; }
    },
    errors: {
      notFound: {
        title: function(d) { return "Ei löytynyt"; },
        message: function(d) { return "Valitettavasti tätä etäesittelyä ei löytynyt."; }
      },
      unknown: {
        title: function(d) { return "Hups! Jokin meni pieleen."; },
        message: function(d) { return "Vaikuttaisi siltä, että järjestelmässä tapahtui odottamaton virhe."; }
      },
      unauthorized: {
        title: function(d) { return "Hups! Jokin meni pieleen."; },
        message: function(d) { return "Voit yrittää uudelleen ilmoittautumalla etäesittelyyn osoitteessa op-koti.fi kohteen omalta sivulta."; }
      }
    }
  },
  player: {
    videoDisabled: function(d) { return "Video on pois päältä"; },
    hostVideoDisabled: function(d) { return "Välittäjä on sulkenut videon toistaiseksi."; },
    hostDisconnected: function(d) { return "Välittäjä ei ole paikalla"; },
    completeDialog: {
      title: function(d) { return "Haluatko varmasti päättää etäesittelyn?"; },
      message: function(d) { return "Sulkemalla etäesittelyn yhteys katkaistaan kaikilta käyttäjiltä."; }
    },
    leaveDialog: {
      title: function(d) { return "Haluatko varmasti poistua etäesittelystä?"; },
      message: function(d) { return "Poistumalla etäesittelystä yhteytesi esittelyyn katkaistaan."; }
    }
  },
  termsOfService: {
    title: function(d) { return "Tervetuloa etäesittelyyn!"; },
    disclaimer1: function(d) { return "Esittelyn nauhoittaminen tai kuvaaminen ei ole sallittua. Etäesittelyn yhteydessä toimiva chat on tarkoitettu yleisille kysymyksille kohteesta, joten ethän jaa chattiin arkaluonteista tietoa, kuten henkilötietoja tai tarjousta kohteesta. Väärinkäytösten ilmetessä kiinteistövälittäjällämme on oikeus poistaa osallistujia etäesittelystä."; },
    disclaimer2: function(d) { return "Mikäli haluat tutustumaan kohteeseen paikan päällä, voit varata yksityisnäytön ottamalla yhteyttä kohteen välittäjään sähköpostitse tai soittamalla."; }
  },
  autoplayPolicy: {
    title: function(d) { return "Hyväksy äänentoisto"; },
    description: function(d) { return "Selaimesi estää automaattisen äänentoiston. Ole hyvä ja hyväksy automaattinen äänentoisto, jotta kuulet välittäjän äänen."; },
    accept: function(d) { return "Hyväksy"; }
  },
  chat: {
    title: function(d) { return "Chat"; },
    inputPlaceholder: function(d) { return "Kirjoita kysymys"; },
    welcomeMessage: function(d) { return "Tervetuloa SATO Etäesittelyyn! Chat on tarkoitettu yleisille kysymyksille kohteesta ja viestit ovat kaikkien osallistujien nähtävissä. Ethän jaa chattiin arkaluontoista tietoa, kuten henkilötietoja tai tarjousta kohteesta. Väärinkäytösten ilmetessä kiinteistönvälittäjällämme on oikeus poistaa osallistuja etäesittelystä."; },
    errors: {
      history: function(d) { return "Edellisiä viestejä ei pystytty noutamaan"; }
    },
    removal: {
      title: function(d) { return "Haluatko varmasti poistaa vierailijan etäesittelystä?"; },
      message: function(d) { return "Haluatko varmasti poistaa vierailijan " + d.author + " etäesittelystä?"; }
    },
    authors: {
      host: function(d) { return "Välittäjä"; },
      viewer: function(d) { return "Vierailija " + d.id; },
      unknown: function(d) { return "Tuntematon " + d.id; }
    }
  },
  connectionBrokenAlert: {
    title: function(d) { return "Yhteys palvelimeen katkennut"; },
    description: function(d) { return "Yhteyden muodostamisessa kesti liian kauan. Virhe voi johtua heikosta verkkoyhteydestä. Yritä yhteyden muodostamista uudelleen."; }
  },
  twilioError: {
    title: function(d) { return "Tapahtui virhe"; },
    codes: {
      "0": function(d) { return "Sovellus ei saanut lupaa käyttää laitteesi mediaa."; },
      "53105": function(d) { return "Etäesittelyn osallistujamäärä on täyttynyt. Voit ottaa yhteyttä välittäjään uuden Etäesittelyn tai yksityisesittelyn järjestämiseksi."; },
      "53106": function(d) { return "Etäesittelyä ei löytynyt."; },
      "53118": function(d) { return "Etäesittely on päättynyt."; },
      "53205": function(d) { return "Sinut poistettiin etäesittelystä, koska osallistuit siihen toisella selaimella. Voit osallistua etäesittelyyn vain yhdellä selaimella."; },
      other: function(d) { return "Tapahtui odottamaton virhe. Ole hyvä ja yritä uudelleen."; },
      "531xx": function(d) { return "Etäesittelyyn yhdistäminen epäonnistui."; },
      "201xx": function(d) { return "Pääsyoikeutesi on vanhentunut tai virheellinen. Ole hyvä ja yritä uudelleen."; }
    },
    errorCode: function(d) { return "Virhe: " + d.code; }
  },
  browserUnsupportedError: {
    title: function(d) { return "Selain ei tuettu"; },
    message: function(d) { return "Valitettavasti käyttämääsi selainta ei tueta. Tuemme uusimpia Chrome, Firefox, Safari ja Edge (Chromium) selaimia."; }
  }
}