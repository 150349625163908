import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { NotFoundPage } from "./components/NotFoundPage";
import { LivePage } from "./components/LivePage";
import { Header } from "./components/Header";

function App() {
    return (
        <>
            <Header />
            <Router>
                <Switch>
                    <Route path="/live/:meetingId">
                        <LivePage />
                    </Route>
                    <Route path="/*">
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
