import React, { useEffect, useCallback, useState } from "react";

import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { useMessageGetter } from "react-message-context";

import { useAppState } from "../../state";
import { LoadingPage } from "../LoadingPage";
import { Lobby } from "./Lobby";
import { Player } from "./Player";
import { connectionOptions } from "../../config";
import { isMobile } from "../../lib/utils";
import { VideoProvider } from "../VideoProvider";
import useRoomState from "../../hooks/useRoomState";
import { WebSocketProvider } from "../WebSocketProvider";
import { useMeetingMessageCallback } from "../../hooks/useMeetingMessageCallback";
import useVideoContext from "../../hooks/useVideoContext";
import { SQSMeetingMessage } from "../../domain";
import { ErrorPage } from "../ErrorPage";
import { ConnectionBrokenAlert } from "./ConnectionBrokenAlert";
import { TwilioErrorAlert } from "./TwilioErrorAlert";

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile && connectionOptions?.bandwidthProfile?.video) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
}

type ErrorReason = "unknown" | "notFound" | "unauthorized";

export const LivePage: React.FC = () => {
    const { getSession: getMe, isAuthReady, session, setMeeting, meeting, setTwilioError } = useAppState();
    const { meetingId } = useParams<{ meetingId: string }>();
    const [error, setError] = useState<ErrorReason | null>(null);

    React.useEffect(() => {
        getMe(meetingId).catch((err) => {
            console.error("Failed to get current session", err);
            if (err.response) {
                const { response } = err as AxiosError;
                if (response?.status === 401 || response?.status === 403) {
                    setError("unauthorized");
                    return;
                }
            }
            setError("unknown");
        });
    }, [getMe, meetingId]);

    const onDisconnect = React.useCallback(
        (reason?: "host-ended") => {
            if (reason === "host-ended" && meeting) {
                setMeeting({
                    ...meeting,
                    state: "completed",
                });
            }
        },
        [setMeeting, meeting],
    );

    const messages = useMessageGetter("lobby.errors");

    if (error) {
        return <ErrorPage title={messages(`${error}.title`)} message={messages(`${error}.message`)} />;
    }

    if (!isAuthReady) {
        return <LoadingPage />;
    }

    return (
        <>
            <VideoProvider onError={setTwilioError} options={connectionOptions} onDisconnect={onDisconnect}>
                <WebSocketProvider meetingId={meetingId} identity={`${session.role}:${session.userId}`}>
                    {/* Wrapper contains logic that has to be done inside VideoProvider */}
                    <LivePageWrapper />
                    <ConnectionBrokenAlert />
                </WebSocketProvider>
            </VideoProvider>
            <TwilioErrorAlert />
        </>
    );
};

const LivePageWrapper: React.FC = () => {
    const state = useRoomState();
    const { setPlayerPage, setMeeting } = useAppState();
    const { room } = useVideoContext();

    /**
     * Here we update the AppStateContext based on events in VideProviderContext. The
     * reason for this is that <Header /> -component is living outside of VideoProvider,
     * which means it can't access VideoProviderContext.
     */
    useEffect(() => {
        setPlayerPage(state !== "disconnected");
    }, [state, setPlayerPage]);

    const cb = useCallback(
        (msg: SQSMeetingMessage) => {
            if (msg.type === "meeting-started") {
                // Update meeting state e.g. from "demo" => "live"
                setMeeting(msg.meeting);
            } else if (msg.type === "meeting-ended" && state === "connected") {
                // This should not be run unless the server-side room completed call
                // fails
                room.disconnect();
            }
        },
        [state, room, setMeeting],
    );
    useMeetingMessageCallback(cb);

    return state === "connected" || state === "reconnecting" ? <Player /> : <Lobby />;
};
