import React, { ReactNode } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "@op/opux";
import { ReactComponent as ErrorIcon } from "../../assets/img/error.svg";

export interface ErrorContainerProps {
    /**
     * Error title
     */
    title?: string;
    /**
     * Error message
     */
    message?: string;
    /**
     * Error icon
     */
    icon?: ReactNode;
}

export const ErrorContainer: React.FC<ErrorContainerProps> = ({ icon, title, message }) => (
    <Row center middle style={{ flex: 1 }}>
        <Col
            xs={12}
            sm={9}
            md={6}
            style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}
        >
            {icon || <ErrorIcon style={{ marginBottom: "1.8rem" }} />}
            {title && <h2>{title}</h2>}
            {message && <p style={{ marginTop: "1.8rem" }}>{message}</p>}
        </Col>
    </Row>
);

ErrorContainer.propTypes = {
    icon: PropTypes.node,
    message: PropTypes.string,
    title: PropTypes.string,
};
