import React, { useEffect, useRef } from "react";

import { Button, Col, Row } from "@op/opux";
import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";

import { Session } from "../../domain";
import styled from "styled-components";

const Avatar = styled.div<{ url?: string | null }>`
    width: 128px;
    height: 128px;
    border-radius: 50%;
    border: solid #ff6a10 2px;
    background: url(${(props) => props.url});
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0.9rem auto;
`;

const InfoContainer = styled.div`
    margin: 0.9rem;
`;

interface Props {
    session: Session;
    open: boolean;
    onClose: () => void;
}

export const RealtorModal: React.FC<Props> = ({ session, open, onClose }) => {
    const ref = useRef<any>();
    const misc = useMessageGetter("misc");

    const onModalClose = () => onClose();

    useEffect(() => {
        if (ref.current) {
            if (open) {
                ref.current.open();
            } else {
                ref.current.close();
            }
        }
    }, [ref, open]);

    return (
        <Modal
            appElement={document.getElementById("root") || undefined}
            ref={ref}
            title={misc("realtor")}
            footer={
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Button compact onClick={onModalClose}>
                        <Message id="actions.close" />
                    </Button>
                </div>
            }
        >
            <Row>
                <Col xs>
                    <Avatar url={session.hostAvatarUrl} />
                </Col>
                <Col xs>
                    <InfoContainer>
                        <h3 style={{ marginBottom: "1.2rem" }}>{session.hostName}</h3>
                        {session.hostPhone && (
                            <p>
                                <a href={`tel:${session.hostPhone}`}>{session.hostPhone}</a>
                            </p>
                        )}
                        {session.hostEmail && <a href={`mailto:${session.hostEmail}`}>{session.hostEmail}</a>}
                    </InfoContainer>
                </Col>
            </Row>
        </Modal>
    );
};
