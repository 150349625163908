export const header = {
    padding: 16,
    logo: {
        small: 32,
        large: 48,
    },
};

export const breakpoints = {
    smMax: 768,
};
