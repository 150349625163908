import React, { createContext, useContext, useState, useCallback } from "react";

import { Session } from "../domain";
import { Meeting, MeetingState } from "../domain/meeting";
import { APIClient } from "../api/api-client";
import { AxiosRequestConfig } from "axios";
import { TwilioError } from "twilio-video";

export interface StateContextType {
    meeting: null | Meeting;
    setMeeting(meeting: Meeting | null): void;
    playerPage: boolean;
    activeSinkId: string;
    setActiveSinkId(id: string): void;
    setPlayerPage(value: boolean): void;
    isAuthReady: boolean;
    session: Session;
    setTwilioError(error: TwilioError | null): void;
    twilioError: TwilioError | null;
    /**
     * Get meeting details.
     *
     * @param meetingId Meeting ID
     * @param opts Request opts
     */
    getMeeting(meetingId: string, opts?: AxiosRequestConfig): Promise<Meeting | null>;
    /**
     * Get a Twilio access token for the meeting.
     *
     * @param meetingId Meeting ID
     * @param opts Request opts
     */
    getMeetingToken(meetingId: string, opts?: AxiosRequestConfig): Promise<string | null>;
    /**
     * Update meeting state.
     *
     * @param meetingId Meeting ID
     * @param state New meeting state, e.g. `demo` or `live`
     * @param opts Options
     */
    updateMeeting(meetingId: string, state: MeetingState, opts?: AxiosRequestConfig): Promise<Meeting | null>;
    /**
     * Get current client session. This is based on session cookie.
     *
     * @param meetingId Meeting ID
     */
    getSession(meetingId: string): Promise<void>;
    /**
     * Send a chat message.
     *
     * @param content Message conten
     * @param meetingId Meeting ID
     */
    sendMessage(content: string, meetingId: string): Promise<void>;
    /**
     * Remove a participant from the meeting.
     *
     * @param meetingId Meeting ID
     * @param identity Identity of the participant
     * @param options Request opts
     */
    removeParticipant(meetingId: string, identity: string, options?: AxiosRequestConfig): Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const StateContext = createContext<StateContextType>(null!);

const client = new APIClient();

export default function AppStateProvider(props: React.PropsWithChildren<unknown>) {
    const [meeting, setMeeting] = useState<Meeting | null>(null);
    const [isAuthReady, setAuthReady] = useState(false);
    const [playerPage, setPlayerPage] = useState(false);
    const [twilioError, setTwilioError] = useState<TwilioError | null>(null);
    const [activeSinkId, setActiveSinkId] = useState("default");
    const [session, setSession] = useState<Session>({
        userId: "",
        sessionId: "",
        meetingId: "",
        role: "viewer",
        meetingTitle: null,
        hostEmail: null,
        hostName: null,
        hostPhone: null,
        hostAvatarUrl: null,
        startsAt: null,
        info2: null,
        info1: null,
        isDefault: true,
    });

    const contextValue: StateContextType = {
        session,
        meeting,
        setMeeting,
        isAuthReady,
        playerPage,
        setPlayerPage,
        activeSinkId,
        setActiveSinkId,
        twilioError,
        setTwilioError,
        sendMessage: useCallback((content: string, meetingId: string) => {
            return client.sendMessage(content, meetingId);
        }, []),
        updateMeeting: useCallback(async (meetingId: string, state: MeetingState, opts?: AxiosRequestConfig) => {
            return client.updateMeetingState(meetingId, state, opts).then((meeting) => {
                setMeeting(meeting);
                return meeting;
            });
        }, []),
        getMeeting: useCallback(async (meetingId: string, opts?: AxiosRequestConfig) => {
            return client.getMeeting(meetingId, opts).then((meeting) => {
                setMeeting(meeting);
                return meeting;
            });
        }, []),
        getMeetingToken: useCallback(async (meetingId: string, opts?: AxiosRequestConfig) => {
            return client.getMeetingToken(meetingId, opts);
        }, []),
        getSession: useCallback((meetingId: string) => {
            return client.getSession(meetingId).then((data) => {
                setSession({
                    ...data,
                    isDefault: false,
                });
                setAuthReady(true);
            });
        }, []),
        removeParticipant: useCallback((meetingId: string, identity: string, opts?: AxiosRequestConfig) => {
            return client.removeParticipant(meetingId, identity, opts);
        }, []),
    };
    return <StateContext.Provider value={{ ...contextValue }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error("useAppState() must be used within AppStateProvider");
    }
    return context;
}
