import React from "react";

import styled from "styled-components";

import { ErrorContainerProps, ErrorContainer } from "../ErrorContainer";

const Container = styled.div`
    flex: 1;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ErrorPage: React.FC<ErrorContainerProps> = (props) => (
    <Container>
        <ErrorContainer {...props} />
    </Container>
);
