import React from "react";
import PropTypes from "prop-types";

import { useViewport } from "../../hooks/useViewport";
import { header, breakpoints } from "../../globals";

const styles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
};

interface Props {
    fullHeight?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    noPadding?: boolean;
}

export const Page: React.FC<Props> = ({ children, fullHeight, noPadding }) => {
    const { width } = useViewport();
    const paddingTop = noPadding
        ? undefined
        : header.padding * 2 + (width >= breakpoints.smMax ? header.logo.large : header.logo.small);
    const finalStyles: React.CSSProperties = {
        ...styles,
        flex: fullHeight ? 1 : undefined,
        // minHeight: fullHeight ? "100vh" : undefined,
        paddingTop,
    };
    return <div style={finalStyles}>{children}</div>;
};

Page.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    fullHeight: PropTypes.bool,
};
