import React from "react";

import styled from "styled-components";

import { LocalParticipant } from "../../LocalParticipant";
import { Controls } from "../../Controls";
import { useAppState } from "../../../state";
import { MainParticipant } from "../../MainParticipant";
import { Footer } from "../../Footer";
import { Chat } from "../../Chat";
import { TermsOfService } from "./TermsOfService";
import { TermsOfServiceProvider } from "../../TermsOfServiceProvider";

const Main = styled.main`
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
`;

const Container = styled("div")({
    position: "relative",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
});

export function Player() {
    const { session, meeting } = useAppState();

    const local = session.role === "host";

    return (
        <TermsOfServiceProvider>
            <Main>
                <Container>
                    {local ? <LocalParticipant /> : <MainParticipant />}
                    <Chat />
                </Container>
                {meeting && [<Controls key="controls" meeting={meeting} />, <Footer meeting={meeting} key="footer" />]}
                <TermsOfService role={session.role} />
            </Main>
        </TermsOfServiceProvider>
    );
}
