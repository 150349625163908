import React from "react";

import { Participant } from "twilio-video";
import { useMessageGetter } from "react-message-context";

import usePublications from "../../../hooks/usePublications";
import useVideoContext from "../../../hooks/useVideoContext";
import Publication from "../Publication";
import { ErrorPage } from "../../ErrorPage";

interface Props {
    participant: Participant;
    disableAudio?: boolean;
}

export const ParticipantTracks: React.FC<Props> = ({ participant }) => {
    const { room } = useVideoContext();
    const publications = usePublications(participant);
    const messages = useMessageGetter("player");
    const isLocal = participant === room.localParticipant;
    const hasVideo = publications.some((p) => p.kind === "video");

    return (
        <>
            {publications.map((publication) => (
                <Publication
                    key={publication.kind}
                    publication={publication}
                    participant={participant}
                    isLocal={isLocal}
                    videoPriority="high"
                />
            ))}
            {!hasVideo && <ErrorPage title={messages("videoDisabled")} message={messages("hostVideoDisabled")} />}
        </>
    );
};
