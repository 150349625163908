import React, { useEffect, useRef, useState } from "react";

import { Button } from "@op/opux";
import { Modal } from "@op/opux-modal";

import { useAppState } from "../../../state";
import { Message, useMessageGetter } from "react-message-context";

interface Props {
    meetingId: string;
    isMobile?: boolean;
}

export const CompleteButton: React.FC<Props> = ({ meetingId, isMobile }) => {
    const [completing, setCompleting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const ref = useRef<any>();
    const { updateMeeting } = useAppState();
    const messages = useMessageGetter("player.completeDialog");

    const onComplete = () => {
        setCompleting(true);
        updateMeeting(meetingId, "completed").catch((err) => {
            console.error(err);
            setCompleting(false);
        });
    };

    useEffect(() => {
        if (ref.current) {
            if (modalOpen) {
                ref.current.open();
            } else {
                ref.current.close();
            }
        }
    }, [modalOpen, ref]);
    return (
        <>
            <Button
                size={isMobile ? "default" : "large"}
                onClick={() => setModalOpen(true)}
                className="control"
                primary
                nonInteractive={completing}
                loading={completing}
            >
                <Message id="actions.complete" />
            </Button>
            <Modal
                ref={ref}
                appElement={document.getElementById("root") || undefined}
                title={messages("title")}
                footer={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button compact onClick={() => setModalOpen(false)}>
                            <Message id="actions.cancel" />
                        </Button>
                        <Button compact onClick={onComplete}>
                            <Message id="actions.close" />
                        </Button>
                    </div>
                }
            >
                <p>{messages("message")}</p>
            </Modal>
        </>
    );
};
