import React, { useState, useRef, useEffect, useCallback } from "react";

import styled from "styled-components";
import { Button, Alert } from "@op/opux";
import { IconCancel, IconSend } from "@op/opux-icons/action";
import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";
import { RemoteParticipant } from "twilio-video";

import "./messages.scss";
import { useAppState } from "../../../state";
import useWebSocketContext from "../../../hooks/useWebSocketContext";
import { MessageBubble } from "./MessageBubble";
import useParticipants from "../../../hooks/useParticipants";
import { ChatMessage } from "../../../domain";

interface LiveChatMessage extends ChatMessage {
    /**
     * Set to `true` if the author is connected in the room
     */
    authorConnected?: boolean;
}

const Container = styled("div")({});

const Toolbar = styled.div`
    width: 100%;
    min-height: min-content;
    padding: 0.8rem;
    display: flex;
    justify-content: flex-end;
`;

const MessageContainer = styled.div`
    padding: 0 0.8rem 0 0.8rem;
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
`;

const InputContainer = styled.div`
    border-top: solid 1px #dcdcdc;
    padding: 1.2rem;
    display: flex;
    flex-direction: row;
`;

const Input = styled.input`
    border: none;
    color: #323232;
    font-family: inherit;
    font-size: 1.9rem;
    font-weight: 300;
    line-height: 2.4rem;
    width: 100%;
    :focus {
        outline-width: 0;
    }
    flex-grow: 1;
`;

interface Props {
    onClose: () => void;
    meetingId: string;
    /**
     * We've failed to fetch the full chat history
     */
    messageHistoryError?: boolean;
}

function matchLiveChatMessages(messages: ChatMessage[], participants: RemoteParticipant[]): LiveChatMessage[] {
    return messages.map((message) => ({
        ...message,
        authorConnected: participants.some((p) => p.identity === message.author),
    }));
}

// Character count
const MAX_MESSAGE_LEN = 1024;
const MIN_MESSAGE_LEN = 1;

export const Messages: React.FC<Props> = ({ onClose, meetingId, messageHistoryError }) => {
    const { session, sendMessage: postMessage, removeParticipant } = useAppState();
    const { messages } = useWebSocketContext();
    const participants = useParticipants();
    const [chatMessages, setChatMessages] = useState<LiveChatMessage[]>(matchLiveChatMessages(messages, participants));
    const [value, setValue] = useState("");
    const [sending, setSending] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const translations = useMessageGetter("chat");
    const labels = useMessageGetter("actions");
    const [author, setAuthor] = useState<{ name: string; identity: string } | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [removalPending, setRemovalPending] = useState(false);
    const modalRef = useRef<any>();

    useEffect(() => {
        setChatMessages(matchLiveChatMessages(messages, participants));
    }, [messages, participants, setChatMessages]);

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > MAX_MESSAGE_LEN) {
            return;
        }
        setValue(e.target.value);
    };

    const sendMessage = (e: React.FormEvent) => {
        e.preventDefault();
        if (value.length < MIN_MESSAGE_LEN) {
            return;
        }
        setSending(true);
        postMessage(value, meetingId)
            .then(() => {
                setValue("");
            })
            .catch((err) => {
                // TODO: Deal with errors
                console.error(err);
            })
            .finally(() => setSending(false));
    };

    // Scroll to bottom when new message arrives
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatMessages.length]);

    // Handle modal open
    useEffect(() => {
        if (modalRef.current) {
            if (modalOpen) {
                modalRef.current.open();
            } else {
                modalRef.current.close();
            }
        }
    }, [modalOpen, modalRef]);

    const onRemoveAuthor = useCallback(
        (name: string, identity: string) => {
            setAuthor({ name, identity });
            setModalOpen(true);
        },
        [setAuthor, setModalOpen],
    );

    const confirmRemoval = () => {
        setRemovalPending(true);
        removeParticipant(meetingId, author!.identity)
            .then(() => {
                setModalOpen(false);
                setAuthor(null);
            })
            .catch((err) => {
                // TODO: How do we handle errors here?
                console.error(err);
            })
            .finally(() => {
                setRemovalPending(false);
            });
    };

    const clearRemoval = () => {
        setModalOpen(false);
        setAuthor(null);
    };

    return (
        <>
            <Container className="chat-container">
                <Toolbar>
                    <Button
                        minified
                        ghost
                        iconProps={{ type: "default" }}
                        icon={IconCancel}
                        iconLabel={labels("close")}
                        onClick={onClose}
                    />
                </Toolbar>
                <MessageContainer id="message-container">
                    {messageHistoryError && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Alert error inline="large">
                                <Message id="chat.errors.history" />
                            </Alert>
                        </div>
                    )}
                    <div style={{ flexGrow: 1, width: "inherit" }} />
                    <MessageBubble isOP author="SATO" content={<Message id="chat.welcomeMessage" />} />
                    {chatMessages.map(
                        (
                            { author, generatedAuthorId, content, createdAt: timestamp, authorConnected, authorRole },
                            index,
                        ) => {
                            const readableName = translations(`authors.${authorRole}`, { id: generatedAuthorId });
                            return (
                                <MessageBubble
                                    isLocal={author === `${session.role}:${session.userId}`}
                                    key={index}
                                    content={content}
                                    {...(session.role === "host"
                                        ? {
                                              author: readableName,
                                              timestamp,
                                          }
                                        : null)}
                                    onRemoveAuthor={
                                        session.role === "host" && authorConnected
                                            ? () => onRemoveAuthor(readableName, author)
                                            : undefined
                                    }
                                />
                            );
                        },
                    )}
                    <div ref={messagesEndRef} />
                </MessageContainer>
                <form onSubmit={sendMessage}>
                    <InputContainer>
                        <Input
                            disabled={sending}
                            type="text"
                            placeholder={translations("inputPlaceholder")}
                            value={value}
                            onChange={onValueChange}
                        />
                        <Button
                            type="submit"
                            disabled={sending}
                            loading={sending}
                            ghost
                            icon={IconSend}
                            iconLabel={labels("send")}
                        />
                    </InputContainer>
                </form>
            </Container>
            <Modal
                ref={modalRef}
                appElement={document.getElementById("root") || undefined}
                title={translations("removal.title")}
                footer={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button compact onClick={clearRemoval}>
                            <Message id="actions.cancel" />
                        </Button>
                        <Button compact loading={removalPending} onClick={confirmRemoval}>
                            <Message id="actions.removeParticipant" />
                        </Button>
                    </div>
                }
            >
                <p>{translations("removal.message", { author: author?.name })}</p>
            </Modal>
        </>
    );
};
