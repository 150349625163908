import React from "react";

import { Button } from "@op/opux";
import { IconMicrophoneOn, IconMicrophoneOff } from "@op/opux-icons/control";
import { useMessageGetter } from "react-message-context";

import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle";

export const ToggleAudioButton: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const labels = useMessageGetter("actions");
    return (
        <Button
            size={isMobile ? "default" : "large"}
            minified
            onClick={toggleAudioEnabled}
            icon={isAudioEnabled ? IconMicrophoneOn : IconMicrophoneOff}
            iconLabel={labels("toggleAudio")}
            iconProps={{ type: "default" }}
            className="control"
        />
    );
};
