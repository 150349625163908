import React, { useState } from "react";

import { Button, ButtonRow } from "@op/opux";
import { Message, useMessageGetter } from "react-message-context";
import styled from "styled-components";

import { Meeting } from "../../../../domain/meeting";
import { ReactComponent as WelcomeIllustration } from "../../../../assets/img/Welcome_user_illustration.svg";
import { Session } from "../../../../domain";
import { RealtorModal } from "../../../RealtorModal";
import { IconBubbleCircled, IconInfoCircled, IconRoomsCircled } from "../../../icons";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    text-align: center;
`;

const ImgContainer = styled.div`
    padding: 2.4rem 1.8rem;
    flex: 1;
    width: 70%;
`;

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 1.8rem;
`;

const BtnLabel = styled.span`
    margin-top: 1.2rem;
`;

interface ViewerLobbyProps {
    meeting?: Meeting | null;
    session: Session;
    joinPending?: boolean;
    onJoin: () => void;
}

export const ViewerLobby: React.FC<ViewerLobbyProps> = ({ meeting, onJoin, joinPending, session }) => {
    const messages = useMessageGetter("lobby.viewer");
    const misc = useMessageGetter("misc");
    const [open, setOpen] = useState(false);
    const ref = React.useRef<any>();

    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    // Scroll into the join button when state goes to "live"
    React.useEffect(() => {
        if (meeting?.state === "live") {
            ref.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [meeting]);

    return (
        <Container>
            <ImgContainer>
                <WelcomeIllustration />
            </ImgContainer>
            <h1>{messages("title")}</h1>
            <p>{messages("message1")}</p>
            <p>{messages("message2")}</p>
            {meeting?.state === "live" ? (
                <ButtonRow>
                    <Button primary loading={joinPending} nonInteractive={joinPending} onClick={() => onJoin()}>
                        <Message id="actions.join" />
                        <div ref={ref} />
                    </Button>
                </ButtonRow>
            ) : (
                <MeetingStartsComponent startTime={session.startsAt ? new Date(session.startsAt) : null} />
            )}

            <div style={{ height: "1.8rem" }} />
            <BtnContainer>
                <BtnWrapper>
                    <Button
                        icon={IconInfoCircled}
                        size="large"
                        minified
                        ghost
                        iconProps={{
                            size: "small",
                        }}
                        iconLabel={misc("info")}
                        href={session.info1 || undefined}
                        target="_blank"
                        disabled={!session.info1}
                    />
                    <BtnLabel>{misc("info")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    <Button
                        icon={IconRoomsCircled}
                        size="default"
                        minified
                        ghost
                        iconLabel={misc("floorPlan")}
                        href={session.info2 || undefined}
                        target="_blank"
                        disabled={!session.info2}
                        iconProps={{
                            size: "small",
                        }}
                    />
                    <BtnLabel>{misc("floorPlan")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    <Button
                        icon={IconBubbleCircled}
                        size="large"
                        minified
                        ghost
                        iconProps={{
                            size: "small",
                        }}
                        iconLabel={misc("realtor")}
                        onClick={onOpen}
                    />
                    <BtnLabel>{misc("realtor")}</BtnLabel>
                </BtnWrapper>
            </BtnContainer>
            <RealtorModal session={session} open={open} onClose={onClose} />
        </Container>
    );
};

const MeetingStartsComponent: React.FC<{ startTime: Date | null }> = ({ startTime }) => {
    const messages = useMessageGetter("lobby.viewer");

    if (!startTime) {
        return null;
    }

    return (
        <div style={{ margin: "1.8rem auto", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <p style={{ textTransform: "uppercase" }}>{messages("startsAt")}</p>
            <p style={{ fontSize: "4.6rem" }}>{parseDate(startTime)}</p>
        </div>
    );
};

const parseDate = (date: Date) => {
    const now = new Date();
    if (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
    ) {
        const m = date.getMinutes();
        const h = date.getHours();
        return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
    }
    return new Intl.DateTimeFormat(["fi-FI", "en-GB"], {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    }).format(date);
};
